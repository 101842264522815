import { BASE_URL } from ".";

const fetchWithTimeout = (url, options, timeout = 5000) => {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error("Timeout")), timeout)
    ),
  ]);
};

export const getSubscriptionByEmail = async (email) => {
  try {
  } catch (error) {
    console.error("Error fetching all posts:", error);
    throw error;
  }
};

// CREATE A NEW SUBSCRIPTION
export const createSubscription = async (email) => {
  try {
    const response = await fetchWithTimeout(`${BASE_URL}/subscriptions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      throw new Error("Failed to create subscription");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching all posts:", error);
    throw error;
  }
};
