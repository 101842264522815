import logo from "./logo.svg";
import "./App.css";
import MoneycessityRoutes from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import HeaderComponents from "./components/Header";
import FooterComponents from "./components/Footer";
import { useEffect, useState } from "react";
import { getPostById, listAllPosts } from "./api";
import { ScrollToTop } from "./utils";

function App() {
  const [postID, setPostID] = useState("");
  const [post, setPost] = useState({});
  const [allPosts, setAllPosts] = useState();

  // FETCH ALL POST EVERY 15 MINUTES
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        // get articles data
        const result = await listAllPosts();
        setAllPosts(result?.data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };
    fetchPosts();
    // console.log(allPosts);
  }, [900000]);

  // FETCH POST WHEN POST ID CHANGE
  useEffect(() => {
    const fetchPost = async () => {
      try {
        // get articles data
        const result = await getPostById(postID);
        setPost(result.data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };
    if (postID) fetchPost();
    // console.log("post: ", post);
  }, [postID]);

  return (
    <div className="App">
      <Router basename="/">
        <ScrollToTop />
        <HeaderComponents />
        <MoneycessityRoutes
          post={post}
          allPosts={allPosts}
          setPostID={setPostID}
        />
        <FooterComponents />
      </Router>
    </div>
  );
}

export default App;
