import { Typography } from "../../UI/Typography";
import DecorImage from "../../../assets/spreadsheets/decor1.png";
import BarImage from "../../../assets/spreadsheets/intro/bar.png";
import CalcImage from "../../../assets/spreadsheets/intro/calc.png";
import NoteImage from "../../../assets/spreadsheets/intro/note.png";
import CreditCardImage from "../../../assets/spreadsheets/intro/creditcard.png";

export const IntroSection = ({handleClick}) => {



  return (
    <section className="">
      <div className="pt-20 pb-20 flex flex-col items-center">
        <div className="text-center pt-10 lg:w-[60%] max-w-[600px]">
          <Typography variant="sub-title">GET ACCESS TO</Typography>
          <Typography variant="title">
            3 Free Powerful Financial Tools
          </Typography>
        </div>
        {/* <button onClick={handleClick} className="hover-red text-white font-extrabold bg-black px-6 py-4 hover:opacity-80 my-5">
          Get Them For Free
        </button> */}
        <div className="decoration">
          <img
            src={BarImage}
            className="absolute w-10 md:w-20 lg:w-32 top-32 md:top-40 lg:top-40 left-20  md:left-48 xl:left-80"
          />
          <img
            src={CalcImage}
            className="absolute w-10 md:w-20 lg:w-32 top-32 md:top-40 lg:top-40 right-20 md:right-48 xl:right-80"
          />
          <img
            src={NoteImage}
            className="absolute w-10 md:w-20 lg:w-32 top-44 md:top-80 lg:top-96 left-10 md:left-24 xl:left-40"
          />
          <img
            src={CreditCardImage}
            className="absolute w-10 md:w-20 lg:w-32 top-44 md:top-80 lg:top-96 right-10 md:right-24 xl:right-40"
          />
        </div>
      </div>
      <div className="">
        <Typography variant="h2">Let’s take a walk</Typography>
        <img src={DecorImage} className="w-full py-5"/>
      </div>
    </section>
  );
};
