import SubscribeForm from "../../SubscribeForm";
import { Typography } from "../Typography";
import CloseImage from "../../../assets/popup/close.svg";

export const PopUp = ({ title, subtitle, subtitle2, isPopup, togglePopup }) => {
  // DOWNLOAD SPREADSHEET FILE

  return (
    <div
      style={{
        display: "none",
      }}
      className="z-40 popup-background fixed top-0 w-[100vw] h-[100vh] bg-dark-sec bg-opacity-25 px-5 pt-24 md:px-20 md:pt-28 xl:px-80 xl:pt-32"
    >
      <div
        style={{
          boxShadow: "12px 12px #ff3131",
        }}
        className="rounded-3xl popup-container bg-beige-prim flex flex-col items-center justify-center border-[3px] border-black pt-5 md:pt-10 pb-10 md:pb-14 px-5 md:px-10 "
      >
        <button onClick={togglePopup} className="w-full">
          <img src={CloseImage} className="ml-auto w-10" />
        </button>
        <div className="flex flex-col items-center justify-start my-5">
          <Typography variant="h2">{title}</Typography>
          <Typography variant="">{subtitle}</Typography>
          <Typography variant="">{subtitle2}</Typography>
        </div>
        <SubscribeForm popUpTitle={title} content="I'M IN" aligns="center" />
      </div>
    </div>
  );
};
