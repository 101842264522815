import SubscribeForm from "../SubscribeForm";

const JoinClub = () => {
  return (
    <div className="flex flex-col items-start	gap-3 md:w-2/5">
      <h1 className="text-white text-3xl font-bold md:text-4xl">JOIN OUR CLUB</h1>
      <SubscribeForm content="SUBSCRIBE" />
    </div>
  );
};

export default JoinClub;
