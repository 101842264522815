import SubscribeForm from "../../SubscribeForm";
import { Typography } from "../../UI/Typography";
import LandingImage from "../../../assets/landing/BrianLanding.png";
import BackgroundImage from "../../../assets/landing/WelcomeBackground-1.png";

export const WelcomeSection = () => {
  return (
    <div
      className="flex flex-col justify-start items-center p-3 mt-16 bg-top md:bg-no-repeat md:bg-cover md:bg-bottom md:min-h-[1100px]"
      style={{ backgroundImage: "url(" + BackgroundImage + ")" }}
    >
      <div className="decor-right w-8/12 md:w-6/12 lg:w-4/12 ">
        <img src={LandingImage} />
      </div>
      <div className="main-section flex flex-col justify-center items-center lg:w-3/6">
        <Typography variant="title">
          Free Stock Intrinsic Value Calculator
        </Typography>
        <Typography variant="sub-title">
          By clicking "I'M IN", you will receive the spreadsheet for free and
          agree to subscribe to my email newsletter all about making more money
          and living a richer life.
        </Typography>
        <Typography variant="sub-title">
          No pressure, you can unsubscribe anytime.
        </Typography>
        <SubscribeForm content="I'M IN" aligns="center" />
      </div>
    </div>
  );
};
