import { Typography } from "../../UI/Typography";
import CreditImage from "../../../assets/spreadsheets/credit/Tablet.png";
import DecorImage from "../../../assets/spreadsheets/decor2.png";

export const CreditSection = ({ togglePopup }) => {
  return (
    <section className="credit-container pt-20 md:pt-24">
      <div className="flex flex-col md:flex-row items-center px-2 md:px-20">
        <div className="text-left w-4/6 order-2 md:order-1">
          <Typography variant="h1">1. Credit Card Picker</Typography>
          <Typography variant="h3">
            Easily track and manage all your credit card payments and balances
            in one place.
          </Typography>

          <div>
            <Typography variant="">
              - Automatic calculation of interest and payments.
            </Typography>
            <Typography variant="">
              - Customizable categories for different credit cards.
            </Typography>
            <Typography variant="">
              - Visual summary of your credit card debt and spending patterns.
            </Typography>
          </div>

          <button
            onClick={togglePopup}
            className="credit-download-button download-button hover-dark text-white font-extrabold bg-black px-6 py-4 hover:opacity-80 my-5"
          >
            DOWNLOAD NOW
          </button>
        </div>
        <div className="w-full md:w-2/6 order-1 md:order-2">
          <img src={CreditImage} className="w-4/6 m-auto" />
        </div>
      </div>
      <img src={DecorImage} className="w-full py-5" />
    </section>
  );
};
