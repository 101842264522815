import MagnifyingGlassImage from "../../assets/header/magnifying_glass.svg";

const SearchButton = () => {
  return (
    <button className="w-0 aspect-square rounded-full ml-auto hover:bg-white hover:p-0.5 duration-150 lg:w-10">
      <img src={MagnifyingGlassImage} className="" />
    </button>
  );
};

export default SearchButton;
