export const Typography = ({ variant, children }) => {
  switch (variant) {
    // TITLE
    case "title":
      return (
        <h1 className="font-black uppercase text-red-prim mb-3 text-3xl md:text-6xl">
          {children}
        </h1>
      );
    //SUB TITLE
    case "sub-title":
      return <p className="font-medium md:text-2xl">{children}</p>;

    // HEADING 1
    case "h1":
      return <h1 className="font-black uppercase mb-2 text-4xl md:text-5xl text-red-prim">{children}</h1>;
    // HEADING 2
    case "h2":
      return <h1 className="font-bold uppercase mb-2 text-3xl md:text-4xl">{children}</h1>;
    // HEADING 3
    case "h3":
      return <h1 className="font-bold uppercase text-2xl md:text-3xl">{children}</h1>;

    // REGULAR
    default:
      return <p className="font-normal text-base md:text-xl overflow-hidden text-ellipsis">{children}</p>;
  }
};
