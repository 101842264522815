import SearchIcon from "../../../assets/header/magnifying_glass.svg";
import { Typography } from "../../UI/Typography";

export const FilterSection = ({
  searchTerm,
  onInputChange,
  contentTags,
  selectedTags,
  onTagToggle,
}) => {
  return (
    <div className="">
      <div className="relative w-full h-14 px-8">
        <img src={SearchIcon} className="absolute h-full p-2 box-border" />
        <input
          className="w-full h-full p-0 box-border pl-16 border-black border-2 text-lg"
          type="text"
          value={searchTerm}
          onChange={onInputChange}
          placeholder="Search . ."
        ></input>
      </div>
      <div className="flex flex-wrap justify-end gap-3 mt-3 mx-8">
        {contentTags?.map((tag) => {
          return (
            <button
              className="outline outline-2 rounded-3xl p-2 hover:bg-[#f2eff3]"
              style={{
                backgroundColor: selectedTags.includes(tag)
                  ? "#E6F6EB"
                  : "transparent",
              }}
              key={tag}
              onClick={() => onTagToggle(tag)} // Toggle tag selection
              // $selected={selectedTags.includes(tag)} // Style selected tags
              // style={{ backgroundColor: selectedTags.includes(tag) ? "blue" : "transparent" }}
            >
              <Typography variant="">{tag.toUpperCase()}</Typography>
            </button>
          );
        })}
      </div>
    </div>
  );
};
