import YoutubeImage from "../../assets/footer/youtube.svg";
import TiktokImage from "../../assets/footer/tiktok.svg";
import MediumImage from "../../assets/footer/medium.svg";
import ThreadsImage from "../../assets/footer/threads.svg";
import TwitterImage from "../../assets/footer/twitter.svg";
import { Link } from "react-router-dom";

const LinksItems = [
  { key: "about", url: "/about-me", label: "ABOUT ME" },
  { key: "articles", url: "/articles", label: "ARTICLES" },
  { key: "youtube", url: "https://linktw.in/ZseMUM", label: "YOUTUBE" },
];

const SocialMediaItems = [
  {
    name: "YouTube",
    image: YoutubeImage,
    url: "https://linktw.in/ZseMUM",
  },
  {
    name: "Tiktok",
    image: TiktokImage,
    url: "https://www.tiktok.com/@moneycessity",
  },
  {
    name: "Medium",
    image: MediumImage,
    url: "https://medium.com/@moneycessity",
  },
  {
    name: "Threads",
    image: ThreadsImage,
    url: "https://www.threads.net/@moneycessity",
  },
  {
    name: "Twitter",
    image: TwitterImage,
    url: "https://twitter.com/moneycessity",
  },
];

const Links = () => {
  return (
    <section className="flex flex-col justify-between w-full gap-5 md:w-2/5">
      <ul className="flex flex-wrap w-2/3 gap-x-2 gap-y-6 justify-between md:ml-auto md:gap-x-5 xl:gap-x-[4vw] md:justify-end">
        {LinksItems.map((item) => {
          return (
            <li className="text-white text-start md:text-end" key={item.key}>
              <Link
                to={item.url}
                className="text-3xl font-bold underline-offset-4 md:text-3xl md:no-underline hover:underline"
              >
                {item.label}
              </Link>
            </li>
          );
        })}
      </ul>
      <div className="flex flex-wrap justify-between">
        {SocialMediaItems.map((item) => {
          return (
            <a href={item.url} key={item.name} className="w-10 md:w-12">
              <img
                className="aspect-square hover:p-1 duration-300"
                src={item.image}
              />
            </a>
          );
        })}
      </div>
    </section>
  );
};

export default Links;
