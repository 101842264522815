import {
  FocusSection,
  IntroSection,
  StorySection,
} from "../components/layout/AboutPage";

export const AboutPage = () => {
  return (
    <div className="bg-beige-prim pt-20 md:pt-24">
      <IntroSection />
      <FocusSection />
      <StorySection />
    </div>
  );
};
