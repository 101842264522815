import { Link } from "react-router-dom";
import { Typography } from "../Typography";

export const NavSection = ({toggleMenu}) => {
  const NavItems = [
    { label: "FREE STUFF", url: "/spreadsheets" },
    { label: "About Me", url: "/about-me" },
    { label: "Articles", url: "/articles" },
    { label: "YouTube", url: "https://linktw.in/ZseMUM" },
  ];

  return (
    <div className="nav-section flex flex-col items-end gap-2">
      {NavItems.map((item, i) => {
        return (
          <Link onClick={toggleMenu} key={i} to={item.url} className="text-right hover:text-red-prim">
            {i === 0 ? (
              <Typography variant="h1">{item.label}</Typography>
            ) : (
              <Typography variant="h2">{item.label}</Typography>
            )}
          </Link>
        );
      })}
      <div className="my-2 h-1 w-11/12 bg-black" />
    </div>
  );
};
