import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import PrevArrow from "../../../assets/landing/arrow-left.svg";
import NextArrow from "../../../assets/landing/arrow-right.svg";
import { Typography } from "../../UI/Typography";
import { Link } from "react-router-dom";

const ListCarouselItem = [
  {
    thumbnail_url:
      "https://media.beehiiv.com/cdn-cgi/image/format=auto,width=800,height=421,fit=scale-down,onerror=redirect/uploads/asset/file/eeb1a6e8-cb7f-4e58-b647-949e017e1dab/0064.png",
    title: "How Kamala Makes Me F**K RICH With the 2024 Election",
    preview_text:
      "Kamala Harris is clearly more progressive than Joe Biden, but how much will her economic policy differ? Understanding economic policy positions provides an advantage over the broader market.",
    id: "id_1",
  },
  {
    thumbnail_url:
      "https://media.beehiiv.com/cdn-cgi/image/format=auto,width=800,height=421,fit=scale-down,onerror=redirect/uploads/asset/file/82a31050-e990-4850-bfda-83b49c85b9ec/0063.png",
    title: "How To Get F**K RICH If Trump Wins the 2024 Election",
    preview_text:
      "A second Trump presidency would bring major economic shifts after four years of Biden. Understanding which sectors will benefit and which ones will suffer gives us a leg up on the market.",
    id: "id_2",
  },
  {
    thumbnail_url:
      "https://media.beehiiv.com/cdn-cgi/image/format=auto,width=800,height=421,fit=scale-down,onerror=redirect/uploads/asset/file/83645d33-fe06-4e99-96ce-a46cad7ca5c2/0072.png",
    title: "This AI Just CHANGED Investing FOREVER...",
    preview_text:
      "The first A.I. to make one million dollars has finally been born. It did so in a matter of months. What does the future hold for us humans?",
    id: "id_3",
  },
  {
    thumbnail_url:
      "https://media.beehiiv.com/cdn-cgi/image/format=auto,width=800,height=421,fit=scale-down,onerror=redirect/uploads/asset/file/e5f27c0e-5771-4f49-a093-2bba8367359c/0061_B.png",
    title: "#1 Psychologist Jordan Peterson Explains Why You Suck at Investing",
    preview_text:
      "Psychological pitfalls can sabotage your investing journey, as highlighted by Jordan Peterson. Cognitive biases and emotional instincts lead many investors to make poor decisions, often resulting in returns that lag behind the market.",
    id: "id_4",
  },
  {
    thumbnail_url:
      "https://media.beehiiv.com/cdn-cgi/image/format=auto,width=800,height=421,fit=scale-down,onerror=redirect/uploads/asset/file/ff04dcd8-d616-4ddb-961f-b40de0b262ba/66.png",
    title: "If You are Too SCARED to Invest in Stocks, Read This",
    preview_text:
      "The thought of investing at the worst possible time scares many from investing altogether. However, a market crash isn't the only way to lose money. Not investing is a strategy that carries its own unique risks.",
    id: "id_5",
  },
];

export const CarouselSection = ({ recentPosts }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    initialSlide: 0,

    nextArrow: <img src={NextArrow} />,
    prevArrow: <img src={PrevArrow} />,

    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="carousel-container mx-10">
      <Slider {...settings} className="flex">
        {recentPosts?.map((item) => {
          return (
            <div
              key={item.id}
              className="card-container bg-white border-[3px] border-black rounded-lg p-2 hover-dark min-h-[360px] md:min-h-[475px]"
            >
              <Link
                to={`/article/${item.id}`}
                className="flex flex-col justify-start items-center gap-2 p-2"
              >
                <img src={item.thumbnail_url} className=" outline w-full" />
                <div className="title-container w-full text-start line-clamp-2 md:line-clamp-3 ">
                  <Typography variant="h3">{item.title}</Typography>
                </div>
                <div className="content-container text-start max-h-20 ">
                  <p className="line-clamp-2 sm:line-clamp-3 text-xl">
                    {item.preview_text}
                  </p>
                </div>
                <div className="w-full text-start text-red-prim hover:underline hover:underline-offset-2">
                  <Typography variant="h3">Read More..</Typography>
                </div>
              </Link>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};
