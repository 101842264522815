import { Typography } from "../../UI/Typography";

import IntroImage from "../../../assets/about/intro/Layer6.png";
import Decoration from "../../../assets/about/6543923.png";

export const IntroSection = () => {
  return (
    <section className="intro-section mb-20">
      <div className="flex flex-col justify-center items-center gap-2 md:flex-row md:gap-10">
        <img src={IntroImage} className="w-10/12 md:w-5/12" />
        <div className="text-left w-10/12 md:w-5/12">
          <Typography variant="title">Hi, I am Brian.</Typography>
          <Typography>
            I am an investor, husband, and father of two cats. My mission is to
            help 10,000 people live rich and build wealth through leveraging the
            psychology and philosophy of money, investing knowledge, and proper
            personal finance.
          </Typography>
        </div>
      </div>
      <img src={Decoration} className="ml-auto w-0 md:w-40" />
    </section>
  );
};
